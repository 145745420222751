<template>
  <CRow>
    <CCol col="12" xl="12">
      <Spinner v-if="$store.state.loadSpinner" />

      <CCard v-else>
        <CCardHeader>
          <span>المحافظات </span>

          <CButton
            v-if="can('dashboard.states.store')"
            class="float-right"
            color="info"
            @click="
              $router.push({
                name: `Create State`,
              })
            "
          >
            <CIcon name="cil-plus"></CIcon>
            <span> اضافة محافظة </span>
          </CButton>
        </CCardHeader>
        <CCardBody>
          <div class="table-responsive">
            <table class="table table-striped table-bordered table-hover mb-5">
              <thead>
                <tr class="table-primary">
                  <th scope="col">#</th>
                  <th scope="col">الاسم</th>
                  <th scope="col">الحالة</th>
                  <th scope="col">الاجراءات</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="item in items" :key="item.id">
                  <th scope="row">{{ item.id }}</th>
                  <td>{{ item.name }}</td>
                  <td>
                    <b :class="`text-${getBadge(item.status)}`">
                      {{ item.status == "1" ? "نشط" : "متوقف" }}
                    </b>
                  </td>
                  <td>
                    <CButton
                      v-if="can('dashboard.states.update')"
                      class="mx-1"
                      color="warning"
                      @click="openModal(item)"
                    >
                      <CIcon name="cil-pencil"></CIcon>
                    </CButton>
                    <CButton
                      v-if="can('dashboard.states.destroy')"
                      class="mx-1"
                      color="danger"
                      @click="deleteItem(item.id)"
                    >
                      <CIcon name="cil-trash"></CIcon>
                    </CButton>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <CPagination :active-page.sync="currentPage" :pages="totalPages" />
        </CCardBody>
      </CCard>
    </CCol>
    <edit :form="modalForm" @updateDone="updateDone"></edit>
  </CRow>
</template>

<script>
import Edit from "./Edit";
export default {
  name: "States",
  components: {
    Edit,
  },
  data() {
    return {
      items: [],
      activePage: 1,
      currentPage: 1,
      totalPages: 0,
      limit: 10,
      modalForm: null,
    };
  },
  watch: {
    currentPage: {
      // eslint-disable-next-line no-unused-vars
      handler: function (val, oldVal) {
        this.fetchData(val);
      },
      deep: true,
    },
  },
  created() {
    this.fetchData();
  },
  methods: {
    fetchData(page = 1) {
      this.toggleLoadSpinner();

      this.$http
        .get(`/states?per_page=15&page=${page}`)
        .then((res) => {
          // handle success
          this.items = res.data.data.data;
          this.totalPages = res.data.data.last_page;
          this.toggleLoadSpinner();
        })
        .catch(() => {
          //
          this.toggleLoadSpinner();
        });
    },

    toggleLoadSpinner() {
      this.$store.commit("toggleLoadSpinner");
    },

    deactivate(itemId) {
      console.log(itemId);
    },

    getBadge(status) {
      switch (status) {
        case 1:
          return "success";
        case 0:
          return "danger";
        default:
          "primary";
      }
    },
    pageChange(val) {
      this.$router.push({ query: { page: val } });
    },
    openModal(item) {
      this.modalForm = Object.assign({}, item);
      this.update = true;
      this.$store.commit("set", ["showModal", true]);
    },
    deleteItem(id) {
      this.$swal
        .fire({
          title: "تأكيد الحذف",
          text: "هل انت متأكد من الحذف ؟",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: "نعم",
          cancelButtonText: "لا",
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.$http
              .delete("states/" + id)
              .then((res) => {
                // handle success
                if (res.data.status == 200) {
                  this.fetchData();
                  this.$swal.fire("تم الحذف!", "تم الحذف بنجاح.", "success");
                }
              })
              .catch((error) => {
                // handle error
                console.log(error);
              });
          }
        });
    },
    updateDone() {
      this.fetchData();
    },
  },
};
</script>